import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import type { UploadedFileType } from "@/components/file-manager/file-manager";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatFileSize(bytes: number): string {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${Number.parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
}

export function formatDate(date: Date): string {
	return date.toLocaleDateString("en-US", {
		year: "numeric",
		month: "short",
		day: "numeric",
	});
}

export function generateUniqueId(): string {
	return Math.random().toString(36).substr(2, 9);
}

export async function createZipFile(
	files: { name: string; content: Blob }[],
): Promise<void> {
	const zip = new JSZip();

	files.forEach(async (file) => {
		zip.file(file.name, file.content);
	});

	const content = await zip.generateAsync({ type: "blob" });
	saveAs(content, "files.zip");
}

export const getFileName = (file: UploadedFileType) => {
	const name = file.location.split("/").pop();
	return name?.replace(".folder", "");
};

export const getFileType = (file: UploadedFileType) => {
	const name = file.location.split("/").pop();
	if (name?.includes(".folder")) return "folder";
	if (name === ".folder" || !name) return "folder";
	const extension = name.split(".").pop()!;
	if (["jpg", "jpeg", "png", "gif", "webp"].includes(extension)) return "image";
	if (["mp4", "avi", "mov", "mkv"].includes(extension)) return "video";
	if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(extension))
		return "document";
	return "other";
};

export const getFileLocation = (file: UploadedFileType) => {
	const parts = file.location.split("/");
	const locationParts = parts.slice(0, -1);
	return locationParts.join("/") || "/";
};
