import { useLocation } from "react-router";
import useSWRImmutable from "swr/immutable";
import { useEffect } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon, FlaskConical } from "lucide-react";

import { getFetcher } from "@/_helpers/swr";
import { API_ENDPOINT } from "@/config/api";
import { useClinic } from "@/hooks/useUser";
import type { LabType } from "@/types/api";

import { useLab } from "./state";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

export const NavBarLabSelect = () => {
	const { pathname } = useLocation();
	if (!pathname.startsWith("/lab")) return null;

	const clinic = useClinic();
	const { labId, setLabId } = useLab();
	const { data } = useSWRImmutable<LabType[]>(
		clinic ? `${API_ENDPOINT}/lab/labs/${clinic.clinic_id}` : null,
		getFetcher,
	);

	const selectedLab = data?.find((e) => e.id === labId) || data?.[0];

	const labChangeHandler = (value: string) => {
		setLabId(Number(value));
	};

	useEffect(() => {
		const sltd = data?.find((e) => e.id === labId);
		if (!sltd) {
			const labId = data?.[0]?.id;
			if (labId) setLabId(labId);
		}
	}, [data]);

	return (
		<Select value={selectedLab?.id.toString()} onValueChange={labChangeHandler}>
			<SelectTrigger className="">
				<div className="flex items-center gap-2">
					<FlaskConical className="w-4 h-4" />
					<SelectValue placeholder="Select Lab" />
				</div>
			</SelectTrigger>
			<SelectContent>
				{data?.map((e) => (
					<SelectItem key={e.id} value={e.id.toString()}>
						{e.name}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

export const AppoinmentDateLab = () => {
	const { pathname } = useLocation();
	if (!pathname.startsWith("/lab")) return null;

	const [date, setDate] = useLab((s) => [s.date, s.setDate]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"w-full justify-start text-left bg-transparent font-normal text-black",
						!date && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{date ? format(date, "PPP") : <span>Pick a date</span>}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={setDate as any}
					required
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
};
