import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import React, { memo, useEffect } from "react";
import { useNavBar } from "@/hooks/use-navbar";
import { useDocumentTitle } from "@uidotdev/usehooks";

const PageHeader = memo(({ title, desc }: { title: string; desc: string }) => {
	const { setTitle, setDescription } = useNavBar();

	useEffect(() => {
		setTitle(title);
		setDescription(desc);
	}, [title, desc]);

	useDocumentTitle(`${title} - UNQ`);

	return null;
});

export default PageHeader;

export const Breadcrumbs = React.memo(() => {
	const breadcrumbs = useBreadcrumbs();

	return (
		<Breadcrumb>
			<BreadcrumbList>
				{breadcrumbs.map(({ match, breadcrumb }, index) => {
					const isLast = index === breadcrumbs.length - 1;
					return (
						<React.Fragment key={match.pathname}>
							<BreadcrumbItem key={match.pathname} className="hidden md:block">
								{!isLast && (
									<BreadcrumbLink
										href={match.pathname !== "/" ? match.pathname : "/home"}
									>
										{breadcrumb}
									</BreadcrumbLink>
								)}
								{isLast && <BreadcrumbPage>{breadcrumb}</BreadcrumbPage>}
							</BreadcrumbItem>
							{!isLast && <BreadcrumbSeparator className="hidden md:block" />}
						</React.Fragment>
					);
				})}
			</BreadcrumbList>
		</Breadcrumb>
	);
});
