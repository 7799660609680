import AllRoutes from "@/routes";
import { TooltipProvider } from "@/components/ui/tooltip";

export const App = () => {
	return (
		<TooltipProvider>
			<AllRoutes />
		</TooltipProvider>
	);
};
