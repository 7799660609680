import type { BillProcedureData } from "@/types/api";
import { addMinutes, isAfter, isToday, parse } from "date-fns";
import { getBreakTime } from "./misc";

interface AppointmentData {
	transactions?: Transaction[] | null;
	patient?: {
		payment_due?: number;
	};
	payment_due?: number;
	proc_data?: BillProcedureData[] | null;
	bill_info?: {
		bill_list?: BillItem[] | null;
	};
	NET_PAID?: number;
}

interface Transaction {
	transaction_id: number;
	action_type: string;
	bill_amount: number;
	bill_info?: {
		bill_list?: BillItem[];
	};
}

interface BillItem {
	type: "px" | "rx";
	proc_cli_id: string;
	procedure_id: string;
	procedure_price: string;
	item_price?: string;
	discounted_price?: string;
}

interface PayableBillAmountResult {
	payableAmount: number;
	rxOnlyAmount: number;
	txOnlyAmount: number;
	net_paid: number;
}

export const getPayableBillAmountFromAppoinment = (
	appointmentData: AppointmentData,
	consultation_fee: number | string,
	showDueOnly = false,
): PayableBillAmountResult => {
	const transactions: Transaction[] | null =
		appointmentData.transactions?.sort(
			(a, b) => a.transaction_id - b.transaction_id,
		) || null;

	const ALLOW_PREV_DUE_PAY_ONLY: boolean =
		!!appointmentData.transactions &&
		!!(appointmentData.patient?.payment_due || appointmentData.payment_due);

	const itemList: BillItem[] = [
		{
			type: "px",
			proc_cli_id: "c_fee",
			procedure_id: "",
			procedure_price: `${consultation_fee}`,
		},
		...(appointmentData.proc_data?.map((e) => ({
			type: "px" as const,
			item_price: e.procedure_price.toString(),
			...e,
			proc_cli_id: e.proc_cli_id.toString(),
			procedure_id: e.procedure_id.toString(),
			procedure_price: e.procedure_price.toString(),
		})) || []),
	];

	const prevBill =
		transactions?.[transactions.length - 1]?.bill_info ||
		appointmentData.bill_info ||
		{};
	const billList: BillItem[] = prevBill.bill_list?.length
		? prevBill.bill_list
		: itemList;

	const acc = (prev: number, next: BillItem): number =>
		prev +
		(Number.parseInt(next.procedure_price) ||
			Number.parseInt(next.item_price || "0") ||
			0);

	const discountAcc = (prev: number, next: BillItem): number =>
		prev + Number.parseInt(next.discounted_price || "0");

	const netBillAmount: number = billList.reduce(acc, 0);
	const totalBillDiscount: number = billList.reduce(discountAcc, 0);

	const total_paid: number =
		transactions?.reduce(
			(sum, current) =>
				current.action_type === "payment" ? sum + current.bill_amount : sum,
			0,
		) || 0;

	const total_refund: number =
		transactions?.reduce(
			(sum, current) =>
				current.action_type === "refund" ? sum + current.bill_amount : sum,
			0,
		) || 0;

	const net_paid: number =
		appointmentData.NET_PAID || total_paid - total_refund;

	const rxOnlyAmount: number = billList
		.filter((e) => e.type === "rx")
		.reduce(
			(acc, next) =>
				acc + Number.parseInt(next.item_price || next.procedure_price || "0"),
			0,
		);

	const txOnlyAmount: number = billList
		.filter((e) => e.type === "px")
		.reduce(
			(acc, next) =>
				acc + Number.parseInt(next.item_price || next.procedure_price || "0"),
			0,
		);

	if (ALLOW_PREV_DUE_PAY_ONLY && showDueOnly) {
		return {
			payableAmount:
				appointmentData.patient?.payment_due ||
				appointmentData.payment_due ||
				0,
			rxOnlyAmount,
			txOnlyAmount,
			net_paid: 0,
		};
	}

	return {
		payableAmount: netBillAmount - totalBillDiscount - (net_paid || 0),
		rxOnlyAmount,
		txOnlyAmount,
		net_paid,
	};
};

interface AppointmentStats {
	paidAmount: number;
	partialPaid: number;
	due: number;
	partialDue: number;
	rxOnlyAmount: number;
	txOnlyAmount: number;
	rxPaid: number;
	pxPaid: number;
	isOp: boolean;
}

interface StatisticsAccumulator {
	paidAmount: number;
	partialPaid: number;
	due: number;
	partialDue: number;
	paidCount: number;
	partialPaidCount: number;
	dueCount: number;
	rxOnlyAmount: number;
	txOnlyAmount: number;
	rxPaid: number;
	pxPaid: number;
	rxPaidCount: number;
	pxPaidCount: number;
	totalCount: number;
	walkinCount: number;
	opCount: number;
}

interface SimpleAppointmentData {
	is_tatkal: boolean;
	tatkal_consult_fee: number | string;
	normal_consult_fee: number | string;
	transactions: Transaction[] | null;
	consultation_type: string | null;
}

export const calculateStatitcsForAppoinments = (
	appointments: SimpleAppointmentData[],
): StatisticsAccumulator =>
	appointments
		.map((e: SimpleAppointmentData) => {
			const {
				net_paid,
				payableAmount,
				rxOnlyAmount,
				txOnlyAmount,
				isBillGenerated,
			} = get_amounts(e);

			if (!isBillGenerated)
				return {
					paidAmount: 0,
					partialPaid: 0,
					due: 0,
					partialDue: 0,
					rxOnlyAmount: 0,
					txOnlyAmount: 0,
					rxPaid: 0,
					pxPaid: 0,
					isOp: false,
				};

			let pendingAmount = payableAmount;
			if (payableAmount < 0) pendingAmount = 0;
			const isPending = pendingAmount > 0;
			const isFullPaid = !isPending;
			const isPartialPaid = net_paid > 0 && isPending;
			const paidAmount = isFullPaid ? net_paid : 0;
			const partialPaid = isPartialPaid ? net_paid : 0;
			const due = isPending && !isPartialPaid ? pendingAmount : 0;
			const partialDue = isPartialPaid ? pendingAmount : 0;
			const rxPaid = isFullPaid ? rxOnlyAmount || 0 : 0;
			const pxPaid = isFullPaid ? txOnlyAmount || 0 : 0;
			const isOp = e.consultation_type !== "walkin";

			return {
				paidAmount,
				partialPaid,
				due,
				partialDue,
				rxOnlyAmount,
				txOnlyAmount,
				rxPaid,
				pxPaid,
				isOp,
			};
		})
		.reduce(
			(acc: StatisticsAccumulator, curr: AppointmentStats) => {
				acc.paidAmount += curr.paidAmount;
				acc.partialPaid += curr.partialPaid;
				acc.due += curr.due;
				acc.partialDue += curr.partialDue;

				if (curr.paidAmount > 0) acc.paidCount += 1;
				if (curr.partialPaid > 0) acc.partialPaidCount += 1;
				if (curr.due > 0) acc.dueCount += 1;
				acc.rxOnlyAmount += curr.rxOnlyAmount;
				acc.txOnlyAmount += curr.txOnlyAmount;
				acc.rxPaid += curr.rxPaid;
				acc.pxPaid += curr.pxPaid;
				if (curr.rxPaid > 0) acc.rxPaidCount += 1;
				if (curr.pxPaid > 0) acc.pxPaidCount += 1;
				acc.totalCount += 1;
				const isWalkin = !curr.isOp;
				if (curr.isOp) acc.opCount += 1;
				if (isWalkin) acc.walkinCount += 1;
				return acc;
			},
			{
				paidAmount: 0,
				partialPaid: 0,
				due: 0,
				partialDue: 0,
				paidCount: 0,
				partialPaidCount: 0,
				dueCount: 0,
				rxOnlyAmount: 0,
				txOnlyAmount: 0,
				rxPaid: 0,
				pxPaid: 0,
				rxPaidCount: 0,
				pxPaidCount: 0,
				totalCount: 0,
				walkinCount: 0,
				opCount: 0,
			},
		);

export const get_amounts = (
	row: Omit<SimpleAppointmentData, "consultation_type">,
) => {
	const consultation_fee = Number(
		row.is_tatkal ? row.tatkal_consult_fee : row.normal_consult_fee,
	);

	const { payableAmount, rxOnlyAmount, txOnlyAmount } =
		getPayableBillAmountFromAppoinment(row, consultation_fee);
	const transactions = row.transactions || null;

	const total_paid =
		transactions?.reduce(
			(sum, current) =>
				current.action_type === "payment" ? sum + current.bill_amount : sum,
			0,
		) || 0;
	const total_refund =
		transactions?.reduce(
			(sum, current) =>
				current.action_type === "refund" ? sum + current.bill_amount : sum,
			0,
		) || 0;

	const isBillGenerated = !!transactions;
	const net_paid = total_paid - total_refund;
	return {
		payableAmount,
		total_paid,
		total_refund,
		net_paid,
		rxOnlyAmount,
		txOnlyAmount,

		isBillGenerated,
	};
};

interface IAppoinmentData {
	appointment_id: string | number;
	appointment_date: string;
	initial_op_time: string;
	is_op_active: boolean;
	is_doctor_in_op: boolean;
	break_start: string | null;
	break_duration: number | null;
	procedure_minutes: number | null;
	token_number?: number;
	status: string;
}
interface IGetOpTimeProps {
	allAppoinments: IAppoinmentData[];
	currentAppoinmentId: string | number;
}

interface IAppDataMinimal {
	initial_op_time: string;
	is_op_active: boolean;
	procedure_minutes: number | null;
}

export const calculateTime = (
	appoinments: IAppDataMinimal[],
): { time: Date; procedure_minutes: number } => {
	let time = new Date();
	let procedure_minutes = 0;

	for (let i = 0; i < appoinments.length; i++) {
		const appoinment = appoinments[i];
		const timeExtended = isAfter(
			addMinutes(time, procedure_minutes),
			parseTime(appoinment.initial_op_time),
		);
		const minutesToAdd = i === 0 && !appoinment.is_op_active ? 5 : 0;
		const currTime = timeExtended
			? addMinutes(time, procedure_minutes + minutesToAdd)
			: parseTime(appoinment.initial_op_time);
		if (i === 0) {
			time = parseTime(appoinments[i].initial_op_time);
		}
		procedure_minutes = appoinment.procedure_minutes || 0;
		time = currTime;
	}
	return {
		time,
		procedure_minutes,
	};
};

export const parseTime = (t: string, date: Date = new Date()) =>
	parse(t, "HH:mm:ss", date);

export const getOpTime = ({
	allAppoinments,
	currentAppoinmentId,
}: IGetOpTimeProps) => {
	if (!allAppoinments?.length) return new Date();
	const appoinments = allAppoinments
		?.sort((a, b) => {
			if (a?.token_number && b?.token_number) {
				return a.token_number - b.token_number;
			}
			return Number(a.appointment_id) - Number(b.appointment_id);
		})
		.filter((e) => e.status === "Pending");
	const currentAppoinment = appoinments?.find(
		(e) => e.appointment_id === currentAppoinmentId,
	)!;

	if (!currentAppoinment?.appointment_date) return new Date();
	const date = parse(
		currentAppoinment?.appointment_date,
		"yyyy-MM-dd",
		new Date(),
	);
	if (!isToday(date)) {
		return parseTime(currentAppoinment?.initial_op_time);
	}
	const currentIndex = appoinments?.findIndex(
		(app) => app.appointment_id === currentAppoinmentId,
	);
	const { time } = calculateTime(appoinments?.slice(0, currentIndex + 1));
	const { toAdd } = getBreakTime(
		currentAppoinment?.break_start,
		currentAppoinment?.break_duration,
	);
	return addMinutes(time, toAdd);
};

export const calculateStatitcs = (appoinments: SimpleAppointmentData[]) => {
	const statis = appoinments.map((e) => {
		const {
			net_paid,
			payableAmount,
			rxOnlyAmount,
			txOnlyAmount,
			isBillGenerated,
		} = get_amounts(e);

		if (!isBillGenerated)
			return {
				paidAmount: 0,
				partialPaid: 0,
				due: 0,
				partialDue: 0,
				rxOnlyAmount: 0,
				txOnlyAmount: 0,
				rxPaid: 0,
				pxPaid: 0,
				isOp: false,
			};
		let pendingAmount = payableAmount;
		if (payableAmount < 0) pendingAmount = 0;
		const isPending = pendingAmount > 0;
		const isFullPaid = !isPending;
		const isPartialPaid = net_paid > 0 && isPending;
		const paidAmount = isFullPaid ? net_paid : 0;
		const partialPaid = isPartialPaid ? net_paid : 0;
		const due = isPending && !isPartialPaid ? pendingAmount : 0;
		const partialDue = isPartialPaid ? pendingAmount : 0;
		const rxPaid = isFullPaid ? rxOnlyAmount || 0 : 0;
		const pxPaid = isFullPaid ? txOnlyAmount || 0 : 0;
		const isOp = e.consultation_type !== "walkin";
		return {
			paidAmount,
			partialPaid,
			due,
			partialDue,
			rxOnlyAmount,
			txOnlyAmount,
			rxPaid,
			pxPaid,
			isOp,
		};
	});

	return statis.reduce(
		(acc, curr) => {
			acc.paidAmount += curr.paidAmount;
			acc.partialPaid += curr.partialPaid;
			acc.due += curr.due;
			acc.partialDue += curr.partialDue;

			if (curr.paidAmount > 0) acc.paidCount += 1;
			if (curr.partialPaid > 0) acc.partialPaidCount += 1;
			if (curr.due > 0) acc.dueCount += 1;
			//   if (curr.partialDue > 0) acc.partialDueCount += 1;
			acc.rxOnlyAmount += curr.rxOnlyAmount;
			acc.txOnlyAmount += curr.txOnlyAmount;
			acc.rxPaid += curr.rxPaid;
			acc.pxPaid += curr.pxPaid;
			if (curr.rxPaid > 0) acc.rxPaidCount += 1;
			if (curr.pxPaid > 0) acc.pxPaidCount += 1;
			acc.totalCount += 1;
			const isWalkin = !curr.isOp;
			if (curr.isOp) acc.opCount += 1;
			if (isWalkin) acc.walkinCount += 1;
			return acc;
		},
		{
			paidAmount: 0,
			partialPaid: 0,
			due: 0,
			partialDue: 0,
			paidCount: 0,
			partialPaidCount: 0,
			dueCount: 0,
			rxOnlyAmount: 0,
			txOnlyAmount: 0,
			rxPaid: 0,
			pxPaid: 0,
			rxPaidCount: 0,
			pxPaidCount: 0,
			totalCount: 0,
			walkinCount: 0,
			opCount: 0,
			//   partialDueCount: 0,
		},
	);
};
