import { create } from "zustand";

type NavBarState = {
	show: boolean;
	title: string;
	description: string;
	setShow: (show: boolean) => void;
	setTitle: (title: string) => void;
	setDescription: (description: string) => void;
	toggleShow: () => void;
};

export const useNavBar = create<NavBarState>()((set) => ({
	show: true,
	title: "",
	description: "",
	setShow: (show) => set({ show }),
	setTitle: (title) => set({ title }),
	setDescription: (description) => set({ description }),
	toggleShow: () => set((state) => ({ show: !state.show })),
}));
