const BACKEND = import.meta.env.VITE_BACKEND_URL;
const API_VERSION = "mob_api/v1";
export const BACKEND_URL = BACKEND;
export const API_ENDPOINT = `${BACKEND}/${API_VERSION}`;
export const AVATAR_API = "https://ui-avatars.com/api/?name=";
export const GEOCODE_API = `https://maps.googleapis.com/maps/api/geocode/json?key=${
	import.meta.env.VITE_LOCATION_API_KEY
}&region=in&address=`;
export const STORAGE_DOMAIN = import.meta.env.VITE_STORAGE_DOMAIN;
export const PG_DOMAIN = import.meta.env.VITE_CASHFREE_URL;
export const BACKEND_NEW = import.meta.env.VITE_BACKEND_NEW_URL;
