import { memo, useEffect, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useUser } from "@/hooks/useUser";
import { Toaster } from "../ui/toaster";
const link = ["www.unqscs.com", "www.unqpro.com", "www.unq.ai"];
const LayoutTVScreen = () => {
	const user = useUser();
	useLayoutEffect(() => {
		if (user === null) {
			console.log("user is null");
			window.location.href = "/";
		}
		if (user?.signedIn === false) {
			window.location.href = "/";
		}
	}, [user]);

	if (!user) return <div>Loading...</div>;

	return (
		<div className="flex flex-col w-full min-h-screen">
			<div className="bg-white flex flex-col grow">
				<Outlet />
				<Toaster />
				<div className="h-14 flex items-center px-10 mt-auto border-t">
					<div className="text-2xl font-bold">
						UNQ
						<span className="text-xl ml-1 font-thin leading-7">
							Practice Management System
						</span>
					</div>
					<div className="ml-auto font-extralight text-xl">
						<Link />
					</div>
				</div>
			</div>
		</div>
	);
};

const Link = memo(() => {
	const [index, setIndex] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % link.length);
		}, 3000);
		return () => clearInterval(interval);
	}, []);
	return <div>{link[index]}</div>;
});

export default LayoutTVScreen;
